import {GraphQLClient} from 'graphql-request';
import environment from 'config/environment';

export class CoreClient extends GraphQLClient {
  private static instance: CoreClient;

  private constructor(token: string) {
    super(environment.settings.apiUrl.coreApi, {
      headers: {
        'X-Proxy-Headers': 'true',
        'authorization': `Bearer ${token}`,
      },
    });
  }

  static getInstance(token: string) {
    if (!this.instance) {
      this.instance = new CoreClient(token);
    }
    return this.instance;
  }
}
