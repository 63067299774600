const commonvars = {
  apiUrl: {
    proposal: process.env.ONSMART_PROPOSAL_API_V1_URL,
    adi: process.env.SERVICE_ADI_API_URL,
    availability: process.env.ONSMART_AVAILABILITY_API_V2_URL,
    mad: process.env.GSP_MAD_API_V3_PANEL_URL,
    coreApi: process.env.ONSMART_CORE_API_V1_URL,
  },
  availability: {
    apiKey: process.env.ONSMART_AVAILABILITY_API_KEY,
    url: process.env.ONSMART_AVAILABILITY_API_URL,
  },
  w3w: {
    url: process.env.W3W_API_URL,
    key: process.env.W3W_API_KEY,
  },
  googleMaps: {
    url: process.env.GOOGLE_MAPS_URL,
  },
  demographics: {
    apiKey: process.env.DMP_DEMOGRAPHICS_API_KEY,
    url: process.env.DMP_DEMOGRAPHICS_URL,
  },
  mapbox: {
    token: process.env.MAPBOX_API_KEY,
    styleUrl: process.env.MAPBOX_STYLE_URL,
  },
  sentry: {
    dsn: process.env.SENTRY_PUBLIC_DSN,
    release: process.env.BUDDY_EXECUTION_REVISION,
  },
};

export default {
  currentType: process.env.NODE_ENV || 'development',
  settings: commonvars,
};
