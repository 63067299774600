import {FragmentUpload} from 'services/graphql/__generated__/FragmentUpload';
import {getSignedUrl as _getSignedUrl} from 'services/graphql/upload-service';

type Callback = (r: FragmentUpload) => void;

export const getSignedUrl = (path: string, rename: string, token: string) => (
  file: File,
  callback: Callback,
) => {
  upload(file.name, file.type, path, rename, token)
    .then((data) => {
      callback(data);
    })
    .catch((error) => {});
};

const upload = async (
  file: string,
  contentType: string,
  path: string,
  rename: string,
  token: string,
): Promise<FragmentUpload> => {
  const response = await _getSignedUrl(
    {
      data: {
        path,
        rename,
        name: file,
        type: contentType,
      },
    },
    token,
  );
  return response.getSignedUrl;
};
