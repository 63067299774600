import {gql} from 'graphql-request';

import {CoreClient} from './core-client';

import {GetSignedUrlVariables} from './__generated__/GetSignedUrl';
import {FragmentUpload} from './__generated__/FragmentUpload';

type UploadResponse = {
  getSignedUrl: FragmentUpload;
};

const FRAGMENT_UPLOAD = gql`
  fragment FragmentUpload on Upload {
    contentType
    filename
    originalFilename
    publicUrl
    signedUrl
  }
`;

const GET_SIGNED_URL = gql`
  query GetSignedUrl($data: UploadInput!) {
    getSignedUrl(data: $data) {
      ...FragmentUpload
    }
  }
  ${FRAGMENT_UPLOAD}
`;

export const getSignedUrl = (variables: GetSignedUrlVariables, token: string) => {
  return CoreClient.getInstance(token).request<UploadResponse, GetSignedUrlVariables>(
    GET_SIGNED_URL,
    variables,
  );
};
